<template>
  <div id="st-container" class="st-container">
    <v-select
      v-model="selected"
      :options="optionList"
      dir="rtl"
      :clearable="false"
      :placeholder="$t(`search-tab.choose-${type}`)"
      class="style-chooser"
    >
      <template #selected-option="{ label }">
        <div style="display: flex; align-items: baseline;">
          <span style="margin-left:10px;">
            <i :class=" type === 'dest' ? 'fa fa-map-marker-alt' : 'fa fa-hotel'" aria-hidden="true"></i>
          </span>
          {{ label }}
        </div>
      </template>
      <template v-slot:option="{ label }">
        <span style="margin-left:10px;font-size:1.5rem;">
          <i :class=" type === 'dest' ? 'fa fa-map-marker-alt' : 'fa fa-hotel'" aria-hidden="true"></i>
        </span>
        {{ label }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'Select',
  components: {
    vSelect,
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
    }),
    optionList() {
      const { options, type, lang } = this;
      if (!options) return [];
      return options.map((item, inx) => {
        // eslint-disable-next-line no-nested-ternary
        const value = type === 'dest' ? item.code : type === 'hotel' ? item.hotelId : item;
        // eslint-disable-next-line no-nested-ternary
        const label = type === 'dest' ? `${item.countryName[lang]} - ${item.name[lang]}` : type === 'hotel' ? item.hotelName[lang] : item;
        return {
          id: inx,
          value,
          label,
        };
      });
    },
  },
  watch: {
    selected() {
      const { type, selected } = this;
      if (!this.selected) return;
      this.$emit('changeDestination', { type, selected, _uid: this._uid });
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
  },
  methods: {
    resetData() {
      this.selected = null;
    },
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type='search'] {
  margin: 0;
  padding: 0;
  border: 0;
}
.filter-tab .st-container .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
}
.vs__selected {
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.style-chooser .vs__selected-options {
  display: contents;
}
</style>
